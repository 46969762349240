import axios from "axios";

import Tags from "@/components/tags";
import Tag from "@/components/tag";
import Button from "@/components/button";
import Input from "@/components/input";

export default {
    name: "modal-report",
    props: ["heading", "text", "datatype", "data", "automated"],
    data() {
        return {
            showAlert: false,
            requireHeading: true,
            flags: [],
            finalTags: [],
            uploadedFiles: [],
            savedFile: "",
            imageCaption: "",
            postingDate: "",
            originalFile: null,
        };
    },
    methods: {
        cancelModal() {
            this.$modal.hide("report-modal");
        },
        uploadToJIRA() {
            this.$modal.hide("report-modal");
            if (!this.automated) {
                this.$toasted.show("Uploading to JIRA", {
                    duration: 5000,
                });
                axios
                    .post(`${process.env.VUE_APP_PORTAL_URL}/orca/review/screen/case/${this.$store.getters.getCaseId}`, {
                        upload_pdf: true,
                        categories: this.data.data.categories,
                        pdf: this.data.data.report_pdf,
                    })
                    .then((response) => {
                        this.$toasted.show("PDF uploaded to JIRA", {
                            duration: 5000,
                        });
                    });
            }
        },
    },
    computed: {
        getReportURL() {
            return `${process.env.VUE_APP_PORTAL_URL}${this.data.data.report_pdf}`;
        },
    },
    components: {
        "neo-tags": Tags,
        "neo-tag": Tag,
        "neo-button": Button,
        "neo-input": Input,
    },
};
